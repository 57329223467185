var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"singleEmailTemplateContainer cursor-pointer",on:{"click":function($event){$event.stopPropagation();return _vm.chooseTemplate(
        _vm.emailTemplate,
        _vm.templateMode == 'saved' ? `saved` : `unlayer`
      )}}},[(_vm.templateMode == 'saved')?_c('div',[_c('LazyImage',{key:_vm.emailTemplate.design_image,staticStyle:{"height":"250px","width":"100%","aspect-ratio":"16 /9","object-fit":"contain"},attrs:{"src":_vm.emailTemplate.design_image,"alt":""}})],1):_c('div',[_c('LazyImage',{key:_vm.emailTemplate.image,staticStyle:{"height":"250px","width":"100%","aspect-ratio":"16 /9","object-fit":"contain"},attrs:{"src":_vm.emailTemplate.image,"alt":""}})],1),_c('div',{staticClass:"mt-3 px-2 d-flex align-items-center justify-content-between pb-3"},[_c('p',{staticClass:"templateName m-0"},[_vm._v(_vm._s(_vm.emailTemplate.name))]),_c('div',{staticClass:"d-flex gap"},[_c('div',{attrs:{"title":"Select Template"},on:{"click":function($event){$event.stopPropagation();return _vm.chooseTemplate(
        _vm.emailTemplate,
        _vm.templateMode == 'saved' ? `saved` : `unlayer`
      )}}},[_c('svg',{attrs:{"width":"21px","height":"21px","viewBox":"0 0 24 24","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('g',{attrs:{"id":"SVGRepo_tracerCarrier","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('g',{attrs:{"id":"SVGRepo_iconCarrier"}},[_c('path',{attrs:{"d":"M4 12.6111L8.92308 17.5L20 6.5","stroke":"#667085","stroke-width":"2","stroke-linecap":"round","stroke-linejoin":"round"}})])])]),_c('div',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.previewEmailTemplate(
            _vm.emailTemplate.id,
            _vm.templateMode == 'saved' ? `saved` : `unlayer`
          )}}},[_c('svg',{attrs:{"width":"21","height":"20","viewBox":"0 0 21 20","fill":"none","xmlns":"http://www.w3.org/2000/svg"}},[_c('g',{attrs:{"clip-path":"url(#clip0_508_1755)"}},[_c('path',{attrs:{"d":"M1.5 10C1.5 10 4.83333 3.33334 10.6667 3.33334C16.5 3.33334 19.8333 10 19.8333 10C19.8333 10 16.5 16.6667 10.6667 16.6667C4.83333 16.6667 1.5 10 1.5 10Z","stroke":"#667085","stroke-width":"1.66667","stroke-linecap":"round","stroke-linejoin":"round"}}),_c('path',{attrs:{"d":"M10.6667 12.5C12.0474 12.5 13.1667 11.3807 13.1667 10C13.1667 8.61929 12.0474 7.5 10.6667 7.5C9.28595 7.5 8.16667 8.61929 8.16667 10C8.16667 11.3807 9.28595 12.5 10.6667 12.5Z","stroke":"#667085","stroke-width":"1.66667","stroke-linecap":"round","stroke-linejoin":"round"}})]),_c('defs',[_c('clipPath',{attrs:{"id":"clip0_508_1755"}},[_c('rect',{attrs:{"width":"20","height":"20","fill":"white","transform":"translate(0.666656)"}})])])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }